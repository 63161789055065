import useSourceFlowCollection from "@/hooks/useSourceFlowCollection";
import data from "@sourceflow/blog_posts.json";
import topics from "@sourceflow/blog_topics.json";
import author from "@sourceflow/team.json";

export const useBlogPosts = ({ limit = null } = {}) => {
  let items = useSourceFlowCollection(data, { topics, author }).map((i) => ({
    ...i,
    href: `/blog/${i.url_slug}`,
  }));

  if (limit) {
    items = items.slice(0, limit);
  }

  return items;
};

export default useBlogPosts;
